import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import './style.scss';

function HiddenPhone({ phone }) {
    const [isVisible, setIsVisible] = useState(false);

    const showNumber = (e) => {
        if (!isVisible) {
            e.preventDefault();
        }
        setIsVisible(true);
    };

    return (<><FontAwesomeIcon icon={faPhone} className="phone-icon" />
        <a className={"phone-link" + (isVisible ? " phone-link-open" : "")} href={'tel:' + (isVisible ? phone : '')} onClick={showNumber}>
            {isVisible ? phone : "CALL US"}
        </a>
    </>
    );
}

export default HiddenPhone;