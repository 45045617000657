import { useEffect, useState } from "react";
import ArticlesList from "../../common/articles-list";
import ImageHead from "../../common/image-header";
import Timeline from "../../common/timeline";
import { getNewsInfo } from "../../service";
import "./style.scss";

function NewsList() {
    const [latestNews, setLatestNews] = useState([]);
    const [archivedNews, setArchivedNews] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        async function loadData() {
            const newsInfo = await getNewsInfo();
            const latestCount = 7;

            const ln = newsInfo.news.slice(0, latestCount);
            setLatestNews(ln);

            var an = newsInfo.news.slice(latestCount);
            setArchivedNews(an);

            setArticles(newsInfo.articles);
        };

        loadData();
    }, []);

    return (<div className="dfc-news-list">
        <ImageHead imgUrl="/img/masthead/News.jpg" />
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3 className="text-left mt-4 mb-4">News And Articles</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <Timeline items={latestNews} />
                    <Collapse header='[Show Archive]'>
                        <Timeline items={archivedNews} />
                    </Collapse>
                </div>
                <div className="col-xs-12 col-md-6">
                    <ArticlesList articles={articles} />
                </div>
            </div>
        </div>
    </div>)

}

function Collapse({ header, children }) {
    return (
        <div>
            <p>
                <a data-bs-toggle="collapse" href="#showMoreCollapse" role="button" aria-expanded="false" aria-controls="showMoreCollapse">
                    {header}
                </a>
            </p>
            <div className="collapse" id="showMoreCollapse">
                {children}
            </div>
        </div>
    );
}

export default NewsList;