import PageSection from "../../../common/page-section";

function WhoWeAre({ whoWeAre }) {
    return (
        <div className="dfc-who-we-are">
            <PageSection
                header='<b class="ps-title-highlight">Who</b> we are'
                description={whoWeAre?.message}>
                <div className='container' data-aos="fade-down">
                    <p>{whoWeAre?.contentText}</p>
                </div>
            </PageSection>
        </div>);
}

export default WhoWeAre;