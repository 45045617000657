import HiddenPhone from "../hidden-phone";
import { showMessage } from "../message-box";
import "./style.scss";

function Footer({ sections, contactInfo }) {
    return (<footer className='footer text-left'>
        <div className='container'>
            <div className='row'>
                {sections?.map((sec, index) => <div key={index} className="col-xs-1 col-md-4 col-lg-2 pt-4">
                    <FooterSection section={sec} contactInfo={contactInfo} />
                </div>)}
            </div>
            <div className='row mt-2'>
                <div className='col'>

                </div>
                <div className='col-auto'>
                    <p className='text-muted'>
                        Copyright © Digital Facilities Corporation 2002 - {new Date().getFullYear()}
                    </p>
                </div>
                <div className='col'>

                </div>
            </div>
        </div>
    </footer>);
}

function FooterSection({ section, contactInfo }) {
    var contentSelector = () => {
        if (section.items)
            return <FooterSectionListContent items={section.items} contactInfo={contactInfo} />
        if (section.rows)
            return <FooterSectionGridContent rows={section.rows} contactInfo={contactInfo} />
        return null;
    }

    return (<div className='footer-section pr-3'>
        <h3 className="h5">{section.title}</h3>
        <div>
            {contentSelector()}
        </div>
    </div>
    );
}

function FooterSectionGridContent({ rows, contactInfo }) {
    return rows.map((row, index) => <div className="row" key={index}>
        {row?.columns?.map((col, cIndex) => <div className="col" key={cIndex}>
            <FooterSectionListItem key={cIndex}
                item={col}
                contactInfo={contactInfo} />
        </div>)}
    </div>);
}

function FooterSectionListContent({ items, contactInfo }) {
    return items.map((item, index) => <FooterSectionListItem key={index} item={item} contactInfo={contactInfo} />);
}

function FooterSectionListItem({ item, contactInfo }) {
    const handleDeliveryModal = (e, title, address) => {
        e.preventDefault();
        let addrString = address.join('<br/>');
        showMessage(addrString, title);
    };

    if (item.url) {
        const target = item.target || "_self";
        return (<div>
            <a href={item.url} target={target}>
                {item.icon && <img src={item.icon} alt="footer icon"
                    {...item.iconWidth ? { width: item.iconWidth } : {}}
                    className={'me-1 mb-1 img-fluid ' + (item.iconClassName ? item.iconClassName : '')}></img>}
                <span>{item.name}</span>
            </a>
        </div>);
    }

    if (item.type === "@HiddenPhone") {
        return <HiddenPhone phone={contactInfo.phone} />
    }

    if (item.type === "@HiddenPostAddress") {
        return <div><a href="/" onClick={(e) => handleDeliveryModal(e, 'Contact Info', contactInfo.postAddress)}>Contact Info</a></div>;
    }

    if (item.type === "@HiddenDeliveryAddress") {
        return <div><a href="/" onClick={(e) => handleDeliveryModal(e, 'Package delivery', contactInfo.deliveryAddress)}>Package delivery</a></div>;
    }

    if (item.type === "@ContactUs") {
        return <div>
            <a href="/" data-bs-toggle="modal"
                data-bs-target="#contactUsModal"
                data-title={item?.param?.title}
                data-contact-type={item?.param?.contactType}
                data-show-request-demo={item?.param?.showRequestDemo}>{item.name}</a>
        </div>;
    }

    if (item.name) {
        return (<div> {item.name} </div>)
    }

    return null;
}


export default Footer;