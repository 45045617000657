import ArticlesList from "../../../common/articles-list";
import PageSection from "../../../common/page-section";
import Timeline from "../../../common/timeline";
import "./style.scss";

function LatestNews({ message, items, articles }) {
    return (<div className="dfc-latest-news-section">
        <PageSection className='dfc-latest-news-section'
            header='<b class="ps-title-highlight">Latest</b> news'
            description={message}>
            <div className='container' data-aos="flip-up">
                <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                        <Timeline items={items} showMore={{ 'url': '/news-list', 'text': '[Show All]' }} />
                    </div>
                    <div className='col-md-6 col-sm-12 text-start'>
                        <ArticlesList articles={articles} />
                    </div>
                </div>
            </div>
        </PageSection>
    </div>
    );
}

export default LatestNews;