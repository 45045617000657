import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import "./style.scss";
import logo from '../../logo/logo-top.png';
import { sendContactUs } from "../../service";
import { showMessage } from "../message-box";
import { useLocation } from "react-router-dom";

function ContactUsModal({ contactUsInfo, contactInfo }) {

    const [title, setTitle] = useState("");
    const [showRequestDemo, setShowRequestDemo] = useState(false);
    const [contactType, setContactType] = useState("");
    const location = useLocation();
    const showContactUsButtonRef = useRef(null);

    useEffect(() => {
        const onContactUsOpen = (e) => {
            const newTitle = e.relatedTarget?.dataset?.title || "Contact Us";
            if (newTitle)
                setTitle(newTitle);

            const newShowRequestDemo = e.relatedTarget?.dataset?.showRequestDemo;
            setShowRequestDemo(newShowRequestDemo === undefined || newShowRequestDemo === "true");

            const ct = e.relatedTarget?.dataset?.contactType || "Contact Us";
            setContactType(ct);
        };

        const myModalEl = document.getElementById('contactUsModal')
        myModalEl.addEventListener('show.bs.modal', onContactUsOpen);

        return () => {
            myModalEl.removeEventListener('show.bs.modal', onContactUsOpen);
        };
    }, []);

    useEffect(() => {
        if (location.search
            && showContactUsButtonRef.current
            && location.search.toLowerCase() === "?contact-us") {
            showContactUsButtonRef.current.click();
        }
    }, [location, showContactUsButtonRef]);

    return (
        <>
            <button
                id="mbShowHiddenContactUs"
                ref={showContactUsButtonRef}
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#contactUsModal"
            >
                Launch Contact Us
            </button>
            <div className="modal fade" id="contactUsModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <img className='logo-title' src={logo} alt='logo' />
                            <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ContactUsForm contactUsInfo={contactUsInfo}
                                showRequestDemo={showRequestDemo}
                                contactType={contactType}
                                contactInfo={contactInfo} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function ContactUsForm({ contactUsInfo, contactType, showRequestDemo, contactInfo }) {

    const originPath = window.location.origin || '/';

    const [filteredProfileOption, setFilteredProfileOption] = useState([]);
    const [requestDemo, setRequestDemo] = useState();
    const [failMessage, setFailMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [contactUsModel, setContactUsModel] = useState({
        baseUrl: originPath,
        product: 'FM-Pro'
    });

    useEffect(() => {
        setContactUsModel({ ...contactUsModel, contactType: contactType });
    }, [contactType]);

    let handelProfileTypeChange = (e) => {
        let value = +e.target.value;
        let flt = contactUsInfo.profileOptions.filter(item => item.profileTypeId === value);
        setFilteredProfileOption(flt);

        handleSelectChange(e);
    };

    let handleSelectChange = (e) => {
        const target = e.target;
        const name = target.id;

        var cm = { ...contactUsModel, [name]: target.options[target.selectedIndex].text };

        setContactUsModel(cm);
    };

    let handleInputChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.id;

        var cm = { ...contactUsModel, [name]: value };
        setContactUsModel(cm);
    };

    let handlRequestDemoChange = (e) => {
        const target = e.target;
        const name = target.id;
        const value = target.checked;

        var cm = { ...contactUsModel, [name]: value };
        setRequestDemo(target.checked);
        setContactUsModel(cm);
    }

    var handleSubmit = async (e) => {
        e.preventDefault();

        setFailMessage('');

        if (!contactUsModel.profileType
            || !contactUsModel.profileOption
            || !contactUsModel.organization
            || !contactUsModel.title
            || !contactUsModel.contactName
            || !contactUsModel.phone
            || !contactUsModel.eMail
            || !contactUsModel.product
            || (contactUsModel.requestDemo && !contactUsModel.contactSchedule)) {
            setFailMessage('Please fill all required fields before send.');

            return;
        }

        setIsLoading(true);
        try {
            await sendContactUs(contactUsModel);
            showMessage('Thank you for leaving contact request. <br/> Digital Facilities sales will contact you soon.', 'Contact Us');
        }
        catch
        {
            const phone = contactInfo.phone;
            setFailMessage(`Sorry. Unable to submit request, please try later or call ${phone}.`)
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <form className="dfc-contact-us-form">
            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <ContactDropDown id="profileType"
                        label="Profile Type*"
                        items={contactUsInfo.profileTypes}
                        hasEmpty={true}
                        onChange={handelProfileTypeChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6">
                    <ContactDropDown id="profileOption"
                        label="Profile Option*"
                        items={filteredProfileOption}
                        hasEmpty={true}
                        onChange={handleSelectChange}
                    />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="organization">Organization*</label>
                <input type="text" className="form-control" id="organization" name="organization" onChange={handleInputChange} required />
            </div>
            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="title">Title*</label>
                    <input type="text" className="form-control" id="title" name="title" onChange={handleInputChange} required />
                </div>
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="contactName">Contact Name*</label>
                    <input type="text" className="form-control" id="contactName" name="contactName" onChange={handleInputChange} required />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="phone">Phone*</label>
                    <InputMask id="phone" name="phone" className="form-control" mask="999-999-9999" maskChar=" " onChange={handleInputChange} required />
                </div>
                <div className="col-sm-12 col-md-6">
                    <label htmlFor="eMail">E-Mail*</label>
                    <input type="email" className="form-control" id="eMail" name="eMail" onChange={handleInputChange} required />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-12 col-md-6">
                    <ProductTypesDropDown productTypes={contactUsInfo.productTypes} onChange={handleSelectChange} />
                </div>
                {showRequestDemo && <div className="col-sm-12 col-md-3">
                    <label htmlFor="requestDemoId"></label>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="requestDemo"
                            onChange={handlRequestDemoChange}
                        />
                        <label className="form-check-label" htmlFor="requestDemoId">Request Demo</label>
                    </div>
                </div>}
            </div>
            {requestDemo && <div className="form-group">
                <ContactDropDown id="contactSchedule"
                    label="Contact Schedule*"
                    items={contactUsInfo.contactSchedule}
                    hasEmpty={true}
                    onChange={handleSelectChange}
                />
            </div>}
            <div className="form-group">
                <label htmlFor="notes">Notes</label>
                <textarea className="form-control" id="notes" rows="2" name="notes" onChange={handleInputChange}></textarea>
            </div>
            <div className="row">
                <div className="col">
                    {failMessage && <span className="text-danger">{failMessage}</span>}
                </div>
                <div className="col-auto">
                    <button className="btn btn-corporate  mb-2 float-right"
                        type="submit"
                        disabled={isLoading}
                        onClick={handleSubmit}>
                        {isLoading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
                        Send
                    </button>
                </div>
            </div>
        </form>
    );
};

function ContactDropDown({ id, label, items, selectedItem, onChange, isRequired = true, hasEmpty = true }) {
    return (<>
        <label htmlFor={id}>{label}</label>
        <select id={id}
            className="form-select"
            onChange={onChange}
            aria-label={label}
            {...(items.length === 0 ? { disabled: "disabled" } : {})}
            {...(isRequired ? { required: "required" } : {})}>
            {hasEmpty && <option value="">Select</option>}
            {items?.map(pt => <option key={pt.id} value={pt.id}>{pt.name}</option>)}
        </select>
    </>);
};

function ProductTypesDropDown({ productTypes, onChange }) {
    let grps = GroupBy(productTypes, "groupName");

    return (
        <>
            <label htmlFor="product">Product*</label>
            <select className="form-select"
                id="product"
                onChange={onChange}
            >
                {Object.keys(grps).map((group, index) =>
                    <optgroup key={index} label={group}>
                        {grps[group].map(pt => <option key={pt.id} value={pt.id}>{pt.name}</option>)}
                    </optgroup>)}
            </select>
        </>
    );
};

function GroupBy(array, key) {
    return array.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export default ContactUsModal;