import './style.scss'
import logoFull from '../../logo/logo-r-full.png';
import HiddenPhone from '../hidden-phone';

function MainNavbar({ contactInfo, navigation }) {
    return <div className="dfc-main-navbar">
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
                <MainLogo />
                <ToggleButton />
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                    <div>
                        <div className="d-flex justify-content-end phone-box">
                            <HiddenPhone phone={contactInfo.phone} />
                        </div>
                        <ul className="navbar-nav">
                            {navigation?.map((item, index) => <NavigationMenuItem key={index} item={item} />)}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
}

function NavigationMenuItem({ item }) {
    if (!item)
        return;

    const isDropDown = item.items || false;
    if (isDropDown)
        return <DropDownNavigationMenuItem item={item} />
    if (item.type === "@ContactUs")
        return <ContactUsMenuItem item={item} />
    return <FlatNavigationMenuItem item={item} />

}

function DropDownNavigationMenuItem({ item }) {
    return (
        <li className="nav-item dropdown">
            <a
                className="nav-link dropdown-toggle"
                href={item.url}
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                {item.name}
            </a>
            <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
            >
                {item.ddDescription && <label className="ps-2 font-weight-bold">{item.ddDescription}</label>}
                {item.items.map((item, index) => (<li key={index}>
                    <a className="dropdown-item" href={item.url}>
                        {item.name}
                    </a>
                </li>))}
            </ul>
        </li>
    );
}

function ContactUsMenuItem({ item }) {
    return (<li className="nav-item">
        <a className="nav-link" aria-current="page" href="/" data-bs-toggle="modal" data-bs-target="#contactUsModal">
            {item.name}
        </a>
    </li>);
}

function FlatNavigationMenuItem({ item }) {
    return (<li className="nav-item">
        <a className="nav-link" aria-current="page" href={item.url}>
            {item.name}
        </a>
    </li>);
}

function MainLogo() {
    return <a className="navbar-brand" href="/">
        <img className='logo-full' src={logoFull} alt='logo' />
    </a>;
}

function ToggleButton() {
    return <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span className="navbar-toggler-icon" />
    </button>;
}

export default MainNavbar;