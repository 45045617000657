import "./style.scss";

function TestimonialSlider({ id, testimonials }) {
    const elemId = id || "csTestimonials";
    const randomTestimonials = getRandomTestimonials(testimonials);

    return (

        <div id={elemId} className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {randomTestimonials.map((test, index) => <div key={index} className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
                    <Testimonial message={test.message} author={test.author} occupation={test.occupation} />
                </div>)}
            </div>
            <div className="carousel-indicators">
                {randomTestimonials.map((test, index) =>
                    <button key={index} type="button" data-bs-target={'#' + elemId} data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current="true" aria-label="Quote" />)}
            </div>
        </div>
    );
}

function getRandomTestimonials(testimonials) {
    var retVal = testimonials.sort((a, b) => 0.5 - Math.random());

    return retVal.slice(0, 4);
}

function Testimonial({ message, author, occupation }) {
    return (<blockquote className="blockquote">
        {message}
        <span>- {author} ({occupation})</span>
    </blockquote>);
}

export default TestimonialSlider;