import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

function MainSlider({ id, screens }) {
    const elemId = id || "carouselSlides";
    const shuffledScreens = Shuffle(screens);

    const onScrollToMore = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: window.innerHeight - 70,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (<div
        id={elemId}
        className="dfc-main-carousel carousel slide carousel-fade"
        data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-see-more">
                <a href="/" onClick={onScrollToMore} >
                    <span className="highlight h5 font-weight-bold multiple-shadows">See More</span>
                    <br />
                    <FontAwesomeIcon icon={faChevronDown} className="highlight fas fa-2x font-weight-bold multiple-shadows" />
                </a>
            </div>
            {shuffledScreens?.map((item, index) => <CarouselItem key={index} item={item} isActive={index === 0} />)}
        </div>
    </div>
    );
}

function CarouselItem({ item, isActive }) {
    return (<div className={"carousel-item" + (isActive ? " active" : "")}>
        <img src={item.imageUrl} className="d-block" alt="..." />
        <div className="carousel-caption d-none d-md-block">
            <h5 className="multiple-shadows">{GetRandomItem(item.messages)}</h5>
        </div>
    </div>);
}

function Shuffle(array) {
    return array.sort((a, b) => 0.5 - Math.random());
}

function GetRandomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export default MainSlider;