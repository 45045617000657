import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from '../common/layout';
import Home from '../pages/home';
import Customers from '../pages/customers';
import NewsList from '../pages/news-list';
import Content from '../pages/content';

function App() {
  return (
    <div className="dfc-app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/?contact-us" element={<Home />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/news-list" element={<NewsList />} />
            <Route path="/:contentType/:contentPath" element={<Content />} />
            <Route path="/" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
