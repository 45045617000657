import "./style.scss";

function SideBar({ items, title }) {
    return (
        <div className='dfc-sidebar'>
            {title && <h6>{title}</h6>}
            <ul className='list-unstyled'>
                {items?.map((item, index) => 
                    <li key={index} style={(item.marginTop ? {'padding-top': item.marginTop} : {})}>
                        <SideBarLink link={item} />
                    </li>)}
            </ul>
        </div>
    );
}

function SideBarLink({ link }) {
    const target = link.target || "_self";
    return <span>
        <a href={link.url}
            target={target}
            {...(link.type === "@ContactUs" ? { "data-bs-toggle": "modal", "data-bs-target": "#contactUsModal" } : {})}>
            {link.iconUrl && <img src={link.iconUrl} alt='sidebar image' style={{ width: 23, marginRight: 3, marginBottom: 2 }} />}
            {link.contentImageUrl && <img src={link.contentImageUrl} alt='sidebar image' style={{ width: '80%', marginRight: 3, marginBottom: 2 }} />}
            {link.title}
        </a>
    </span>;
}

export default SideBar;