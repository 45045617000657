import PageSection from "../../../common/page-section";
import "./style.scss"

function WhatWeDo({ whatWeDo }) {
    return (
        <div className="dfc-what-we-do-section">
            <PageSection
                header='<b class="ps-title-highlight">What</b> we do'
                description={whatWeDo?.message}>
                <div className='container' data-aos="fade-down">
                    <div className="row">
                        {whatWeDo.items.map((item, index) => <div key={index} className="col-xs-1 col-md-6 col-lg-4">
                            <WhatWeDoCard imageUrl={item.imageUrl} description={item.description} redirectUrl={item.redirectUrl} />
                        </div>)}
                    </div>
                </div>
            </PageSection>
        </div>
    );
}

function WhatWeDoCard({ imageUrl, description, redirectUrl }) {
    return (<div className="card border-0 what-we-do-card ms-2 me-2" data-aos="flip-left">
        <a href={redirectUrl} className='text-decoration-none'>
            <img src={imageUrl} className="card-img-top img-thumbnail" alt="..." />
            <div className="card-body">
                <p className="card-text">{description}</p>
            </div>
        </a>
    </div>);
}

export default WhatWeDo;