import PageSection from "../../../common/page-section";
import TestimonialSlider from "../../../common/testimonial-slider";

function Testimonial({ message, testimonials }) {

    return (<PageSection className='dfc-testimonial-section'
        header='<b class="ps-title-highlight">Customer</b> testimonials'
        description={message}>
        <div className='container' data-aos="zoom-in">
            <TestimonialSlider testimonials={testimonials} />
        </div>
    </PageSection>
    );
}

export default Testimonial;