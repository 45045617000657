import { useEffect, useState } from "react";
import ImageHead from "../../common/image-header";
import PageSection from "../../common/page-section";
import TestimonialSlider from "../../common/testimonial-slider";
import SuccessStoryTable from "./success-story-table";
import { getCustomersInfo, getTestimonialsInfo } from "../../service";

function Customers() {
    const [testimonials, setTestimonials] = useState([]);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        async function loadData() {
            var testmns = await getTestimonialsInfo();
            setTestimonials(testmns);

            var customers = await getCustomersInfo();
            setCustomers(customers);
        }

        loadData();
    }, []);

    return <div className="dfc-customers-page">
        <ImageHead imgUrl='/img/masthead/Customers.jpg' />
        <div className="container">
            <div className="mt-5">
                <PageSection header='<b class="ps-title-highlight">Example</b> Industry deployments'>
                    <SuccessStoryTable items={customers} />
                </PageSection>
            </div>
            <div className="mt-5 mb-5">
                <PageSection header='<b class="ps-title-highlight">Customer</b> testimonials'>
                    <TestimonialSlider testimonials={testimonials} />
                </PageSection>
            </div>
        </div>
    </div>;
}

export default Customers;