import "./style.scss";

function SuccessStoryTable({ items }) {
    return <div className="row">
        {items.map((story, index) => <div className="col-12 col-xl-4 mt-3"><SuccessStoryItem key={index} story={story} /></div>)}
    </div>;
}

function SuccessStoryItem({ story }) {
    return (
        <div className="success-story-card card h-100"
                data-aos="fade-up"
                data-bs-toggle="modal" 
                data-bs-target="#successStoryModal"
                data-title={story.title}
                data-title-img={story.logoUrl}
                data-description={story.description}>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <h5 className="card-title">{story.title}</h5>
                    </div>
                </div>
                <div className="row">
                    {story.logoUrl && <div className="col-auto d-flex align-items-center">
                        <img src={story.logoUrl} className="img-fluid" alt="story logo" width="100" />
                    </div>}
                    <div className="col">
                        <p className="card-text">
                            <span>
                                {truncate(story.description, 70)}
                            </span>
                            <a href='/' data-bs-target="#exampleModal" onClick={(e) => e.preventDefault()}> &gt;&gt;</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function truncate(str, n) {
    var cut = str.indexOf(' ', n);
    if (cut === -1)
        return str;
    return str.substring(0, cut);
}

export default SuccessStoryTable;