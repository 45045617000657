function MessageBox() {
    return <>
        <button
            id="mbShowHiddenButton"
            type="button"
            className="btn btn-primary d-none"
            data-bs-toggle="modal"
            data-bs-target="#messageBoxModal"
        >
            Launch Message Box
        </button>
        <div
            className="modal fade"
            id="messageBoxModal"
            tabIndex={-1}
            aria-labelledby="messageBoxModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="messageBoxModalLabel">
                            Message
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

function showMessage(message, title = "Message") {
    var mbElement = document.getElementById("messageBoxModal");
    if (!mbElement) {
        console.error("Unable to find message box element");
        return;
    }
    const titleElement = mbElement.getElementsByClassName("modal-title")[0];

    if (titleElement) {
        titleElement.innerHTML = title;
    }

    const bodyElement = mbElement.getElementsByClassName("modal-body")[0];
    if (bodyElement) {
        bodyElement.innerHTML = message;
    }

    const btn = document.getElementById("mbShowHiddenButton");
    btn.click();
}

export {
    MessageBox,
    showMessage
};