import { useEffect, useState } from "react";
import "./style.scss";

function SuccessStoryModal() {
    const [title, setTitle] = useState("");
    const [titleImg, setTitleImg] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        const onShowSuccessStory = (e) => {
            const dsTitle = e.relatedTarget?.dataset?.title || "";
            setTitle(dsTitle);
            const dsTitleImg = e.relatedTarget?.dataset?.titleImg || "";
            setTitleImg(dsTitleImg);
            const dsDescription = e.relatedTarget?.dataset?.description || "";
            setDescription(dsDescription);
        };

        const myModalEl = document.getElementById('successStoryModal')
        myModalEl.addEventListener('show.bs.modal', onShowSuccessStory);

        return () => {
            myModalEl.removeEventListener('show.bs.modal', onShowSuccessStory);
        };
    }, []);
    return (
        <div className="modal fade"
            id="successStoryModal"
            tabIndex={-1}
            aria-labelledby="successStoryModal"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        {titleImg && <img className='logo-title' src={titleImg} alt='logo' />}
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        {description}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessStoryModal;