import "./style.scss";

function Breadcrumb({ path, active }) {
    if (!path)
        return <></>;

    return (
        <nav className="dfc-breadcrumb p-2 ps-3" aria-label="breadcrumb">
            <ol className="breadcrumb mb-1">
                {path.map((bc, index) => <li key={index} className="breadcrumb-item"><a href={bc.url}>{bc.title}</a></li>)}
                {active && <li class="breadcrumb-item active" aria-current="page">{active}</li>}
            </ol>
        </nav>
    );
}

export default Breadcrumb;