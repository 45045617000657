import axios from "axios";

async function getContactInfo() {
    const baseUrl = process.env.PUBLIC_URL;
    var result = await axios.get(`${baseUrl}/configuration/contacts.json`);

    return result.data;
}

async function getLayoutInfo() {
    const baseUrl = process.env.PUBLIC_URL;
    var result = await axios.get(`${baseUrl}/configuration/layout.json`);

    return result.data;
}

async function getHomeInfo() {
    const baseUrl = process.env.PUBLIC_URL;
    var result = await axios.get(`${baseUrl}/configuration/home.json`);

    return result.data;
}

async function getNewsInfo() {
    const baseUrl = process.env.PUBLIC_URL;
    var result = await axios.get(`${baseUrl}/configuration/news.json`);

    return result.data;
}

async function getTestimonialsInfo() {
    const baseUrl = process.env.PUBLIC_URL;
    var result = await axios.get(`${baseUrl}/configuration/testimonial.json`);

    return result.data;
}

async function getCustomersInfo() {
    const baseUrl = process.env.PUBLIC_URL;
    var result = await axios.get(`${baseUrl}/configuration/customers.json`);

    return result.data;
}

async function getContentPageInfo(page) {
    const baseUrl = process.env.PUBLIC_URL;
    var pageInfo = await axios.get(`${baseUrl}/configuration/${page}.json`);
    var result = pageInfo.data;

    if (result.bodyUrl)
    {
        var pageBody = await axios.get(`${baseUrl}/configuration/${result.bodyUrl}`);
        result.body = pageBody.data;
    }

    return result;
}

async function sendContactUs(contactUsRequest) {
    var response = await axios.post('/api/cntct', contactUsRequest);
    return response.data;
}

export {
    getContactInfo,
    getLayoutInfo,
    getHomeInfo,
    getNewsInfo,
    getTestimonialsInfo,
    getCustomersInfo,
    getContentPageInfo,
    sendContactUs
};