import { useEffect, useState } from "react";
import MainSlider from "../../common/main-slider";
import { getHomeInfo, getNewsInfo, getTestimonialsInfo } from "../../service";
import "./style.scss";
import WhoWeAre from "./who-we-are";
import WhatWeDo from "./what-we-do";
import ProductsAndServices from "./products-and-services";
import LatestNews from "./latest-news";
import Testimonial from "./testimonial";


function Home() {
    const defaultHomeConfiguration = emptyHomeConfiguration();
    const [homeConfiguration, setHomeConfiguration] = useState(defaultHomeConfiguration);
    const [latestNews, setLatestNews] = useState([]);
    const [articles, setArticles] = useState([]);
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        async function loadData() {
            var data = await getHomeInfo();
            setHomeConfiguration(data);

            const ltstNewsCount = 5;
            var news = await getNewsInfo();
            setLatestNews(news.news.slice(0, ltstNewsCount));
            setArticles(news.articles);

            var testmns = await getTestimonialsInfo();
            setTestimonials(testmns);
        }

        loadData();
    }, []);

    return <div className="dfc-home-page">
        <div className="dfc-home-slider">
            <MainSlider screens={homeConfiguration.screens} />
            <WhoWeAre whoWeAre={homeConfiguration.whoWeAre} />
            <WhatWeDo whatWeDo={homeConfiguration.whatWeDo} />
            <ProductsAndServices productsAndServices={homeConfiguration.productsAndServices} />
            <LatestNews message={homeConfiguration.latestNews.message}
                items={latestNews}
                articles={articles} />
            <Testimonial message={homeConfiguration.testimonials.message} testimonials={testimonials} />
        </div>
    </div>;
}

function emptyHomeConfiguration() {
    return {
        "screens": [],
        "whoWeAre": {
            "message": "",
            "contentText": ""
        },
        "whatWeDo": {
            "message": "",
            "items": []
        },
        "latestNews": {
            "message": ""
        },
        "testimonials": {
            "message": ""
        }
    }
}

export default Home;