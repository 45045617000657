import "./style.scss"

function PageSection({ header, description, children, isAlternative }) {
    return (<div className={"dfc-page-section p-sm-3" + (isAlternative ? "page-section-alter" : "")} data-aos="fade-up">
        <div className='page-section-header h2' dangerouslySetInnerHTML={{ __html: header }}></div>
        <div className='page-section-description text-muted'>{description}</div>
        <div className='page-section-content'>
            {children}
        </div>
    </div>
    );
}

export default PageSection;