import PageSection from "../../../common/page-section";
import "./style.scss";

function ProductsAndServices({ productsAndServices }) {
    return (<PageSection className='dfc-our-services-section'
        header='<b class="ps-title-highlight">Products & Services</b> we offer'
        description={productsAndServices?.message}>
        <div className='container'>
            <div className='row'>
                {productsAndServices?.items.map((sr, index) =>
                    <div className='col-xs-12 col-lg-4' key={index}>
                        <ProductCard imageUrl={sr.bgImageUrl}
                            titleUrl={sr.productLogoUrl}
                            description={sr.description}
                            redirectUrl={sr.redirectUrl} />
                    </div>)
                }
            </div>
        </div>
    </PageSection>
    );
}

function ProductCard({ imageUrl, titleUrl, description, redirectUrl }) {
    return (<div className='card text-center services-card mb-2' data-aos="flip-left">
        <img className='card-img-top' src={imageUrl} alt="card image" />
        <div className='centered w-100'>
            <div className='text-content'>
                <a href={redirectUrl}>
                    <img src={titleUrl} className='img-fluid product-logo' alt="title image" />
                </a>
                <hr />
                <p>{description}</p>
                <hr />
                <a className='btn btn-light' href={redirectUrl}>Learn More</a>
            </div>
        </div>
    </div>
    );
}

export default ProductsAndServices;