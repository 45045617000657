// useDocumentTitle.js
import { useEffect } from 'react'

function useMetaDescription(description) {
    useEffect(() => {
        if (!description)
            return;
        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.setAttribute('content', description);

    }, [description]);
}

function useMetaKeywords(description) {
    useEffect(() => {
        const metaDescription = document.querySelector('meta[name="keywords"]');
        metaDescription.setAttribute('content', description);

    }, [description]);
}

export { useMetaDescription, useMetaKeywords };