import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import ImageHead from "../../common/image-header";
import SideBar from "../../common/side-bar";
import { useMetaKeywords, useMetaDescription } from "../../common/useMetaTag/useMetaDescription";
import { getContentPageInfo } from "../../service";
import "./style.scss";

function Content() {
    const defaultPageInfo = {
        "headerUrl": [],
        "title": "",
        "body": "",
        "bodyUrl": "",
        "breadcrumbpath": [],
        "relatedTopics": [],
        "resources": [],
        "metaDescription": null,
        "metaKeywords": null
    };
    const [pageInfo, setPageInfo] = useState(defaultPageInfo);
    const [headUrl, setHeadUrl] = useState("");

    const { contentType, contentPath } = useParams();
    const path = `${contentType}/${contentPath}`;

    useEffect(() => {
        async function loadData() {
            const pi = await getContentPageInfo(path);
            setPageInfo(pi);

            const index = randomInt(0, pi.headerUrl.length);
            setHeadUrl(pi.headerUrl[index]);
        }

        loadData();
    }, [path]);

    useMetaDescription(pageInfo.metaDescription);
    useMetaKeywords(pageInfo.metaKeywords);


    return (
        <div className="dfc-content-page">
            <ImageHead imgUrl={headUrl} />
            <div className="container mt-3 mb-3 p-0 ps-sm-3 pe-sm-3">
                <div className="row">
                    <div className="col-md col-sm-12">
                        <div className="row mb-3">
                            <div className="col">
                                {pageInfo.breadcrumbPath && <Breadcrumb path={pageInfo.breadcrumbPath} active={pageInfo.title} />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col ps-4 pe-4 mb-4">
                                {pageInfo.title && <h1 className="h3">{pageInfo.title}</h1>}
                                <div dangerouslySetInnerHTML={{ __html: pageInfo.body }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">
                                    {pageInfo.relatedTopics && <div className="dfc-related-topics"><SideBar items={pageInfo.relatedTopics} title='Related Topics' /></div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">
                                    {pageInfo.resources && <SideBar items={pageInfo.resources} title="Resources" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

function randomInt(min, max) {
    return min + Math.floor((max - min) * Math.random());
}

export default Content;