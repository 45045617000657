import "./style.scss";
import moment from "moment"

function Timeline({ items, showMore }) {
    return (<ul className="timeline">
        {items?.map((item, index) => <li key={index}> <a target={item.target} href={item.url}>[{moment(item.date).format('MMM YYYY')}] {item.text}</a></li>)}
        {showMore && <li><a className="text-muted" href={showMore.url}>{showMore.text}</a></li>}
    </ul>);
}

export default Timeline;