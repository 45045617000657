import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getContactInfo, getLayoutInfo } from "../../service";
import ContactUsModal from "../contact-us-modal";
import Footer from "../footer";
import MainNavbar from "../main-navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import SuccessStoryModal from "../../pages/customers/success-story-modal";
import { MessageBox } from "../message-box";

function Layout() {
    var defaultContactInfo = emptyContactInfo();
    var defaultLayoutinfo = emptyLayoutInfo();

    const [contactInfo, setContactInfo] = useState(defaultContactInfo);
    const [layoutInfo, setLayoutInfo] = useState(defaultLayoutinfo);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(() => {
        async function loadData() {
            var contactInfo = await getContactInfo();
            setContactInfo(contactInfo);

            var layoutInfo = await getLayoutInfo();
            setLayoutInfo(layoutInfo);
        };

        loadData();
    }, []);

    return (<div className="dfc-layout">
        <MainNavbar contactInfo={contactInfo}
            navigation={layoutInfo.navigation} />
        <main>
            <Outlet />
        </main>
        <Footer contactInfo={contactInfo}
            sections={layoutInfo?.footer?.sections} />
        <ContactUsModal contactUsInfo={layoutInfo?.contactUs} contactInfo={contactInfo} />
        <SuccessStoryModal />
        <MessageBox />
    </div>)
}

function emptyContactInfo() {
    return {
        "phone": "",
        "postAddress": [],
        "deliveryAddress": []
    };
}

function emptyLayoutInfo() {
    return {
        "navigation": [],
        "footer": {
            "sections": []
        },
        "contactUs": {
            "profileTypes": [],
            "profileOptions": [],
            "productTypes": [],
            "contactSchedule": [],
        }
    };
}

export default Layout;